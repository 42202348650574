//Page shall be as clean as possible
import { Toolbar } from "@mui/material";
import Head from "next/head";
import React, { Fragment } from "react";
import ToResolveClassNameError from "../../components/hoc/ToResolveClassNameError";
import JobListWrapper from "../../components/jobs/JobListWrapper/JobListWrapper";
import SharedChat from "../../components/shared/SharedChat/SharedChat";
import SharedCollapsibleFooter from "../../components/shared/SharedCollapsibleFooter/SharedCollapsibleFooter";
import * as config from "../../config/config";

function JobListPage(props) {
  let metaTitle =
    "New Job Vacancies in Malaysia - Search By Location, Specialisation & Job Type | Hiredly";
  let metaDescription =
    "Easy and fast job search on one of Malaysia's leading job portal. Find remote and work-from-home job vacancies for full-time and internship career opportunities in Malaysia.";

  return (
    <Fragment>
      <Head>
        {/* <!-- Primary Meta Tags --> */}
        <title>{metaTitle}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" key={"ogType"} />
        <meta
          property="og:url"
          content="https://my.hiredly.com/jobs"
          key="ogUrl"
        />
        <meta property="og:title" content={metaTitle} key="ogTitle" />
        <meta
          property="og:description"
          content={metaDescription}
          key="ogDescription"
        />
        <meta
          property="og:image"
          content={
            config.assetDomain + "/images/hiredly/hiredly-meta-image-v1.png"
          }
          key="ogImage"
        />

        {/* <!-- Twitter --> */}
        <meta
          property="twitter:card"
          content="summary_large_image"
          key="twitterCard"
        />
        <meta
          property="twitter:url"
          content="https://my.hiredly.com/jobs"
          key="twitterUrl"
        />
        <meta property="twitter:title" content={metaTitle} key="twitterTitle" />
        <meta
          property="twitter:description"
          content={metaDescription}
          key="twitterDescription"
        />
        <meta
          property="twitter:image"
          content={
            config.assetDomain + "/images/hiredly/hiredly-meta-image-v1.png"
          }
          key="twitterImage"
        />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="canonical" href="https://my.hiredly.com/jobs" />
      </Head>
      <ToResolveClassNameError>
        <Toolbar />
        <JobListWrapper page="job-list-page" />
        <SharedCollapsibleFooter />
        <SharedChat />
      </ToResolveClassNameError>
    </Fragment>
  );
}

export default JobListPage;
